body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    background: #171717;
    overflow-x: hidden;
    font-size: 16px;
}

html {
    overflow-x: hidden;
}

.logo, .button-bar, .register-link, .registration-form h1,
.button-submit, .user-image, .user-tab, .user-tab-active, .button-not-submit {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #1B1B1C inset;
    transition: background-color 5000s ease-in-out 0s;
}

a {
    text-decoration: none;
    color: black;
}

.App {
    min-height: 100vh;
}

main {
    padding-top: 71px;
}

header {
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    background-color: #202020;
    height: 70px;
    width: 100vw;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
}

.button-bar:hover {
    background: #1B1B1C;
    color: #175BDD;
}

.button-bar {
    border-radius: 10px;
    display: flex;
    gap: 5px;
    justify-content: left;
    align-items: center;
    padding: 10px;
    color: #fff;
    height: 25px;
    transition: width .1s linear;
    cursor: pointer;
}

.logo {
    font-weight: bold;
    font-size: 24px;
    margin-left: 20px;
    color: #fff;
}

.main-header-container {
    width: calc(100vw - 420px);
    height: 100%;
    display: flex;
    gap: 1%;
    margin-left: 210px;
    align-items: center;
}

.header-container {
    height: 71px;
    align-items: center;
    display: flex;
}

.registration-form__group, .active-registration-form__group {
    background-color: #1B1B1C;
    display: flex;
    width: 522px;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    padding: 2px;
}

.active-registration-form__group {
    border: 2px solid blue;
    padding: 0;
}

.registration-form__input {
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    border: 0;
    background: #1B1B1C;
    color: #fff;
    font-size: 16px;
    height: 30px;
    width: 100%;
}

.registration-form__input:focus {
    outline: none !important;
}

.button-submit {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #175BDD;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 141px;
    height: 30px
}

.button-submit:hover {
    background-color: #004e92;
}

.main-right-container {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    min-height: calc(100vh - 91px);
}

.main-container {
    color: #fff;
    width: 100%;
    padding: 10px;
    overflow-x: scroll;
}

.menu-left {
    margin-left: 20px;
    cursor: pointer
}

.left-header-container {
    z-index: 2000;
    display: flex;
    width: 180px;
}

.right-header-container {
    height: 100%;
    width: 210px;
    justify-content: right;
    display: flex;
    padding-right: 20px;
    cursor: pointer;
    align-items: center;
}

.text-button-bar {
    margin-left: 10px;
    overflow: hidden
}

.text-container-button-bar {
    width: 100vw;
}

.user-details {
    margin-left: 10px;
}

.user-details h2 {
    color: #fff;
    font-size: 16px;
    margin: 0 0 0 5px
}

.user-details h3 {
    color: #586069;
    font-size: 12px;
    margin: 0 0 0 5px
}

.user-details p {
    margin-top: 1rem;
}

.left-bar {
    justify-content: flex-end;
    transition: min-width .1s linear;
}

.left-bar-container {
    margin: 10px;
    max-height: calc(100vh - 71px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: fixed
}

.left-bar-container::-webkit-scrollbar {
    display: none;
}

.main {
    display: flex;
}

table {
    border-collapse: collapse;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 40px;
}

td, th {
    width: 10%;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

td {
    white-space: pre;
}

th {
    border: 0 solid #fff;
    border-left-width: 1px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-header {
    background: #262626;
    margin: 2px 0;
    display: flex;
    width: calc(100% - 20px);
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.table-body {
    background: #262626;
    margin: 2px 0;
    display: flex;
    width: calc(100% - 20px);
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.top-contacts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save-button {
    width: calc(5% - 22.5px);
    height: 20px;
    background: #0F7003;
    margin: 0 5px 0 0
}

.save-button:hover {
    background: #0E6002;
}

.delete-button {
    width: calc(5% - 22.5px);
    height: 20px;
    margin: 0;
    background: #F90007
}

.delete-button:hover {
    background: #D70005;
}

.edit-button {
    width: calc(5% - 22.5px);
    height: 20px;
    margin: 0 5px 0 0
}

.left-bar-bottom {
    display: flex;
    align-items: center;
    width: 210px;
    margin-bottom: 20px
}

.left-bar-container-absolute {
    justify-content: space-between;
    height: calc(100% - 71px);
    position: static;
}

.left-bar-buttons-absolute {
    gap: 40px;
    display: flex;
    flex-direction: column
}

.left-bar-absolute {
    position: fixed;
    height: 100vh;
    top: 0;
    background: #171717;
    z-index: 2000;
    transition: width .1s linear;
}

.blackout {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background: #000;
    z-index: 1500;
    transition: opacity .1s linear
}

@media (max-width: 1080px) {
    .right-header-container {
        width: 0;
        overflow: hidden;
    }

    .main-header-container {
        width: calc(100vw - 170px);
        margin-left: 150px;
    }
}

@media (max-width: 950px) {
    header {
        transition: height .1s;
    }

    .left-bar {
        display: none;
    }

    .main-right-container {
        justify-content: center;
        width: calc(100vw - 20px);
    }
}
